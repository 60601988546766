import React, { useContext } from 'react';
import c from './notifications.module.scss';
import {
  Icon,
  Route,
  RouterState,
  Sidebar,
  SidebarContainer,
  SidebarItem,
  SidebarItemSwitch,
  SidebarSection,
} from '@monash/portal-react';
import TileButton from '../../common/tile-button/TileButton';
import { AuthContext, MenuContext } from '@monash/portal-frontend-common';
import Admin from '../Admin';
import ACCESS_ROLES from '../../../constants/user-roles';
import SlideOutWrapper from '../../common/slide-out/SlideOutWrapper';
import AllNotifications from './all-notifications/AllNotifications';

const TILE_CONTENT = {
  CRITICAL: {
    name: 'Critical & emergency',
    link: '/critical',
    desc: 'Create and publish critical & emergency notification',
    icon: <Icon.Warning size={24} color="var(--color-intent-attention)" />,
    accessRole: ACCESS_ROLES.NOTIFICATIONS_CRITICAL,
  },
  NON_CRITICAL: {
    name: 'Non-critical',
    link: '/non-critical',
    desc: 'Create and publish non-critical emergency notification',
    icon: <Icon.Alert size={24} color="var(--card-cta-bg-color)" />,
    accessRole: ACCESS_ROLES.NOTIFICATIONS_NON_CRITICAL,
  },
};

const Notifications = ({ root }) => {
  const { menu, handleMenu, closeMenu, menuRef, size } =
    useContext(MenuContext);
  const authCtx = useContext(AuthContext);
  const { redirect } = useContext(RouterState);
  const userRoles = authCtx?.user?.roles;

  // render notifications management "apps"
  // assuming the nav structure would be the same for both
  const renderNotifications = (type, content) => (
    <SidebarContainer onLeft={true}>
      <Sidebar
        title="Notification"
        ifMenu={menu}
        toggleMenu={handleMenu}
        closeMenu={closeMenu}
        size={size}
        menuRef={menuRef}
      >
        <SidebarSection title="Managing for">
          <SidebarItemSwitch text={TILE_CONTENT[type].name} linkTo={root} />
        </SidebarSection>
        <SidebarSection title="Content">
          {/* all notifications */}
          <SidebarItem
            linkTo={root + TILE_CONTENT[type].link}
            text="Notifications"
            icon={<Icon.Home />}
            onMenu={closeMenu}
          />
        </SidebarSection>
      </Sidebar>
      <SlideOutWrapper>{content}</SlideOutWrapper>
    </SidebarContainer>
  );

  return (
    <>
      <Route to={root} exact>
        <Admin>
          <div className={c.container}>
            <div className={c.header}>
              <div className={c.headingGroup}>
                <h1>Notifications management</h1>
                <p>
                  This page is used to manage critical & emergency and
                  non-critical notifications.
                </p>
              </div>
            </div>
            <div className={c.tiles}>
              {Object.values(TILE_CONTENT).map(
                (tile) =>
                  userRoles?.includes(tile.accessRole) && (
                    <TileButton
                      key={tile.name}
                      onClick={() => redirect(`notifications${tile.link}`)}
                      icon={tile.icon}
                      title={tile.name}
                      description={tile.desc}
                    />
                  )
              )}
            </div>
          </div>
        </Admin>
      </Route>
      {userRoles?.includes(ACCESS_ROLES.NOTIFICATIONS_CRITICAL) && (
        <Route to={root + TILE_CONTENT.CRITICAL.link} exact>
          {renderNotifications(
            'CRITICAL',
            <AllNotifications type="CRITICAL" />
          )}
        </Route>
      )}
      {userRoles?.includes(ACCESS_ROLES.NOTIFICATIONS_NON_CRITICAL) && (
        <Route to={root + TILE_CONTENT.NON_CRITICAL.link} exact>
          {renderNotifications(
            'NON_CRITICAL',
            <AllNotifications type="NON_CRITICAL" />
          )}
        </Route>
      )}
    </>
  );
};

export default Notifications;
