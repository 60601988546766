import { Icon, RouterState } from '@monash/portal-react';
import React, { useContext } from 'react';
import c from './applications.module.scss';
import TileButton from '../../common/tile-button/TileButton';

const Applications = () => {
  const { redirect } = useContext(RouterState);

  const allApps = [
    {
      name: 'Home',
      link: '/home',
      desc: 'Portal home updates',
      icon: <Icon.Home />,
    },
    {
      name: 'Links',
      link: '/links/links',
      desc: 'Links CRUD',
      icon: <Icon.Link />,
    },
  ];

  return (
    <div className={c.container}>
      <div className={c.applications}>
        <h1>Available Applications</h1>
        <p>Monash applications content management</p>
        <div className={c.appList}>
          {allApps.map((app) => (
            <TileButton
              key={app.name}
              onClick={() => redirect(`apps${app.link}`)}
              icon={app.icon}
              title={app.name}
              description={app.desc}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Applications;
