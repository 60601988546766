import React, { useState, useContext } from 'react';
import { Button } from '@monash/portal-react';
import c from './user-card.module.scss';
import { APIContext } from '@monash/portal-frontend-common';

const UserCard = ({ data, onEnd, onSelect, active = false }) => {
  const { endImpersonation, startImpersonation } = useContext(APIContext);

  const [isActive, setIsActive] = useState(active);
  const [loading, setLoading] = useState(false);
  const action = () => {
    setLoading(true);
    if (isActive) {
      endImpersonation().then(() => {
        setIsActive(false);
        setLoading(false);
        onEnd && onEnd();
      });
    } else {
      startImpersonation(data.studentId).then((r) => {
        setIsActive(true);
        setLoading(false);
        onSelect && onSelect(r);
      });
    }
  };

  return (
    <div className={c.userCardContainer}>
      <div className={c.userCard}>
        <div>
          <span>
            <strong>{data.name}</strong> {data.studentId}
          </span>
        </div>
        <div>
          <Button type="text" size="small" onClick={action} loading={loading}>
            <span className={isActive ? c.end : null}>
              {isActive ? 'End' : 'Start'} impersonation
            </span>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default UserCard;
