import React from 'react';
import c from './notification-user-group.module.scss';
import { useFormContext } from 'react-hook-form';
import { DropdownWithValue, Icon, IconButton } from '@monash/portal-react';
import { OR_GROUPS } from '../../../../../../../constants/OR-groups';
import availableORGroups from '../../../../../../utilities/mock-data/available-OR-groups';

const NotificationUserGroup = ({ app, userGroups, userGroup, index }) => {
  const { setValue } = useFormContext();

  // filter user groups that have been selected
  const selectableUserGroups = availableORGroups[app.id].reduce(
    (acc, curr) =>
      userGroups.includes(curr) && userGroups[index] !== curr
        ? acc
        : [...acc, curr],
    []
  );

  const mappedSelectableUserGroups = selectableUserGroups.map((x, i) => {
    return { id: i, text: `${x} - ${OR_GROUPS[x]}`, value: x };
  });

  const selectUserGroup = (e) => {
    const newUserGroups = [...userGroups];
    newUserGroups.splice(index, 1, e);
    setValue(app.userGroupsKey, newUserGroups, { shouldValidate: true });
  };

  const removeUserGroup = (index) => {
    const newUserGroups = [...userGroups];
    newUserGroups.splice(index, 1);
    setValue(app.userGroupsKey, newUserGroups, { shouldValidate: true });
  };

  return (
    <div className={c.notificationUserGroup}>
      <DropdownWithValue
        id="userGroup"
        ariaLabelledby="userGroup"
        onChange={(e) => selectUserGroup(e)}
        value={userGroup}
        data={mappedSelectableUserGroups}
        // disable empty dropdown if all user groups have been selected
        disabled={mappedSelectableUserGroups.length === 0}
      />
      <IconButton
        onClick={() => removeUserGroup(index)}
        icon={Icon.Trash}
        size={24}
        aria-label="Delete user group"
      />
    </div>
  );
};

export default NotificationUserGroup;
