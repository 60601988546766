import { deepClone, formatUrl } from '@monash/portal-frontend-common';
import { format } from 'date-fns';

export const getTimeInputFromTimestamp = (timeStamp) => {
  if (!timeStamp) return '';
  const timeInput = format(new Date(timeStamp), 'HH:mm');

  return timeInput;
};

export const getTimestampFromDateAndTimeInputs = (dateInput, timeInput) => {
  if (!dateInput || !timeInput) return null;

  const formattedDateInput = format(new Date(dateInput), 'yyyy-MM-dd');
  const dateTime = formattedDateInput + ' ' + timeInput;
  const timestamp = new Date(dateTime).getTime();

  return timestamp;
};

export const transformNotificationDataForForm = (notification) => {
  if (!notification) return null;

  const newNotification = deepClone(notification);

  // add startTime field
  newNotification.startTime = getTimeInputFromTimestamp(
    newNotification.startDate
  );

  // transform startDate timestamp to date object
  newNotification.startDate = new Date(newNotification.startDate);

  // add endTime field
  newNotification.endTime = getTimeInputFromTimestamp(newNotification.endDate);

  // transform endDate timestamp to date object
  newNotification.endDate = new Date(newNotification.endDate);

  // remove unnecessary data fields
  delete newNotification.history;
  delete newNotification.status;

  return newNotification;
};

export const transformFormDataForSubmission = (formData, startNow, typeId) => {
  const submissionData = deepClone(formData);

  // create startDate timestamp
  submissionData.startDate = startNow
    ? null
    : getTimestampFromDateAndTimeInputs(
        submissionData.startDate,
        submissionData.startTime
      );

  // create endDate timestamp
  submissionData.endDate = getTimestampFromDateAndTimeInputs(
    submissionData.endDate,
    submissionData.endTime
  );

  // remove startTime and endTime (form fields)
  delete submissionData.startTime;
  delete submissionData.endTime;

  // clean up user group (empty selections)
  submissionData.studentUserGroups = submissionData.studentUserGroups.filter(
    (v) => v
  );
  submissionData.staffUserGroups = submissionData.staffUserGroups.filter(
    (v) => v
  );

  // clean up links (format URLs and remove empty links)
  submissionData.links = submissionData.links.reduce((acc, curr) => {
    if (!curr.label && !curr.url) {
      return acc;
    } else {
      return [
        ...acc,
        {
          label: curr.label,
          url: formatUrl(curr.url),
        },
      ];
    }
  }, []);

  // add typeId
  submissionData.type = typeId;

  return submissionData;
};
