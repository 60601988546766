import ColorPicker from '../../common/color-picker/ColorPicker';
import React, { useContext } from 'react';
import c from './theme-builder.module.scss';
import { ThemePreviewContext } from './ThemeBuilder';

const VariablesSetter = ({
  variable,
  txt = null,
  color = null,
  file = null,
  fontFace = null,
}) => {
  const { theme, setTheme } = useContext(ThemePreviewContext);

  const setVariable = (value) => {
    setTheme((theme) => {
      return {
        ...theme,
        variables: {
          ...theme.variables,
          [variable]: value,
        },
      };
    });
  };

  return (
    <div className={c.VariablesSetter}>
      <div className={c.variable}>{variable}</div>
      {color && (
        <ColorPicker color={theme.variables[variable]} onChange={setVariable} />
      )}
      {txt && (
        <input
          type="text"
          className={c.input}
          value={theme.variables[variable]}
          onChange={(e) => setVariable(e.target.value)}
        />
      )}
      {file && <input type="file" />}
      {fontFace && (
        <input
          type="text"
          className={c.input}
          value={theme.fontURL}
          onChange={(e) =>
            setTheme((f) => {
              return { ...f, fontURL: e.target.value };
            })
          }
        />
      )}
    </div>
  );
};

export default VariablesSetter;
