import { Button, Icon, IconButton } from '@monash/portal-react';
import React, { useContext, useState } from 'react';
import c from './slide-out.module.scss';
import { SlideOutContext } from './SlideOutWrapper';
import ActionModal from './action-modal/ActionModal';

const SlideOutForm = ({
  name,
  width,
  heading,
  headerActions,
  submitActions,
  children,
  isDirty,
}) => {
  const { setOpen } = useContext(SlideOutContext);
  const [actionModalProps, setActionModalProps] = useState(null);
  const [isActionModalOpen, setIsActionModalOpen] = useState(null);

  const openActionModal = (props) => {
    setActionModalProps(props);
    setIsActionModalOpen(true);
  };

  return (
    <>
      <ActionModal
        isActionModalOpen={isActionModalOpen}
        setIsActionModalOpen={setIsActionModalOpen}
        actionModalProps={actionModalProps}
        setActionModalProps={setActionModalProps}
      />
      <form
        className={c.slideOutForm}
        style={{ width: width ? `${width}px` : '100%' }}
      >
        <div className={c.header}>
          <h3>{heading || 'Untitled'}</h3>
          <div className={c.actions}>
            {Array.isArray(headerActions) &&
              headerActions.length !== 0 &&
              headerActions?.map((action) => (
                <IconButton
                  icon={action.icon}
                  onClick={
                    action.modalProps
                      ? () => openActionModal(action.modalProps)
                      : action.onClick
                  }
                  key={action.label}
                  disabled={action.disabled}
                  aria-label={action.label}
                />
              ))}
            <IconButton
              icon={Icon.X}
              onClick={
                isDirty
                  ? () =>
                      openActionModal({
                        title: `Close ${name}`,
                        content: 'Your changes will be lost.',
                        cta: {
                          onClick: () => setOpen(false),
                          variant: 'primary',
                          label: 'Close',
                        },
                      })
                  : () => setOpen(false)
              }
              aria-label="Close form"
            />
          </div>
        </div>
        <div className={c.content}>{children}</div>
        {Array.isArray(submitActions) && submitActions.length !== 0 && (
          <div className={c.submitActions}>
            {/* main CTA */}
            {/* TODO: handle submission loading and error states */}
            <Button
              variant="primary"
              size="medium"
              disabled={submitActions[0].disabled}
              onClick={submitActions[0].onClick}
            >
              {submitActions[0].label}
            </Button>
            {/* // TODO: secondary actions (text buttons) */}
          </div>
        )}
      </form>
    </>
  );
};

export default SlideOutForm;
