import { DatePicker, TimePicker } from '@monash/portal-react';
import React, { useEffect } from 'react';
import c from '../notification-form.module.scss';
import { Controller, useFormContext } from 'react-hook-form';
import { getTimestampFromDateAndTimeInputs } from '../utils';
import InlineErrorMessage from '../../../../common/inline-error-message/InlineErrorMessage';

const NotificationEndDateAndTime = () => {
  const {
    watch,
    trigger,
    control,
    formState: { errors, touchedFields },
  } = useFormContext();

  const currentStartDate = watch('startDate');
  const currentStartTime = watch('startTime');
  const currentEndDate = watch('endDate');
  const currentEndTime = watch('endTime');

  const validateEndDate = (value) => {
    if (!value) return 'A date must be selected';

    if (currentEndTime) {
      const currentEndDateTimeStamp = getTimestampFromDateAndTimeInputs(
        value,
        currentEndTime
      );
      if (currentEndDateTimeStamp < Date.now())
        return 'The end date cannot be before the current date';

      const currentStartDateTimeStamp = getTimestampFromDateAndTimeInputs(
        currentStartDate,
        currentStartTime
      );
      if (currentEndDateTimeStamp < currentStartDateTimeStamp)
        return 'The end date and time must be after the start date and time';
    }
  };

  const getErrorMessage = () => {
    if (!currentEndDate && !currentEndTime) {
      return 'A day and a time must be selected';
    } else if (errors.endDate) {
      return errors.endDate?.message;
    } else if (errors.endTime) {
      return 'A time must be selected';
    }
  };

  useEffect(() => {
    // select day is set when textField onBlur fires hence validation must be triggered as an effect
    if (touchedFields.endDate) trigger('endDate');
  }, [currentEndDate, currentEndTime]);

  return (
    <div className={c.inputGroup}>
      <label>End date and time</label>
      <div className={c.dateAndTime}>
        <Controller
          control={control}
          name="endDate"
          rules={{
            validate: validateEndDate,
          }}
          render={({ field: { onBlur, onChange, value } }) => (
            <DatePicker
              disablePast={true}
              dateFormat="dd/MM/yyyy"
              selectedDay={value}
              setSelectedDay={onChange}
              onBlur={onBlur}
            />
          )}
        />
        <Controller
          control={control}
          name="endTime"
          rules={{
            required: true,
          }}
          render={({ field: { onBlur, onChange, value } }) => (
            <TimePicker time={value} onChange={onChange} onBlur={onBlur} />
          )}
        />
      </div>
      {(errors.endDate || errors.endTime) && (
        <InlineErrorMessage message={getErrorMessage()} />
      )}
    </div>
  );
};

export default NotificationEndDateAndTime;
