import {
  Button,
  ComboBox,
  Icon,
  IconButton,
  PopUp,
} from '@monash/portal-react';
import React, { useState } from 'react';
import c from './multi-select.module.scss';

const MultiSelect = ({ label, data, selected, setSelected, strict = true }) => {
  const [popUp, setPopUp] = useState(false);
  const [openEvent, setOpenEvent] = useState(null);
  const [cbText, setCbText] = useState('');

  const dropdownOptions = () => {
    const options = data.filter((d) => !selected.includes(d));
    if (strict) {
      return options;
    } else {
      if (options.includes(cbText) || !cbText) {
        return options;
      } else {
        return [`Create new ${cbText}`, ...options];
      }
    }
  };

  const removeItem = (i) => {
    const removed = [...selected];
    removed.splice(i, 1);
    setSelected(removed);
  };

  return (
    <div className={c.container}>
      {selected?.map((s, i) => (
        <div className={c.selected} key={i}>
          <span>{s}</span>
          <IconButton icon={Icon.X} size={18} onClick={() => removeItem(i)} />
        </div>
      ))}

      {(dropdownOptions().length > 0 || !strict) && (
        <Button
          type="secondary"
          size="medium"
          onClick={(e) => {
            setOpenEvent(e);
            setPopUp(true);
          }}
        >
          Add
        </Button>
      )}

      {popUp && (
        <PopUp sticky openEvent={openEvent}>
          <div className={c.popUp}>
            <div className={c.searchHeader}>
              <span>Add {label}</span>
              <Button
                variant="text"
                size="small"
                onClick={() => setPopUp(false)}
              >
                Cancel
              </Button>
            </div>
            <ComboBox
              data={dropdownOptions()}
              textValue={cbText}
              onChange={(e) => setCbText(e)}
              onSelect={(e) => {
                setSelected([...selected, e]);
                setPopUp(false);
                setCbText('');
              }}
            />
          </div>
        </PopUp>
      )}
    </div>
  );
};

export default MultiSelect;
