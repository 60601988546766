import React from 'react';
export const Data = React.createContext();

const DataProvider = ({ children }) => {
  // TODO: move storage helper + getCurrentDate from Home to FE common to get flexible current date
  const currentDate = Date.now();

  return (
    <Data.Provider
      value={{
        currentDate,
      }}
    >
      {children}
    </Data.Provider>
  );
};

export default DataProvider;
