import React, { useContext, useEffect, useState } from 'react';
import c from '../notifications.module.scss';
import {
  Button,
  Icon,
  LoadingIndicatorPlanet,
  Tabs,
} from '@monash/portal-react';
import {
  NOTIFICATION_STATUS_IDS,
  NOTIFICATION_TYPE_IDS,
} from '../../../../constants/notifications-props';
import {
  APIContext,
  capitaliseFirstWord,
} from '@monash/portal-frontend-common';
import NotificationCard from './notification-card/NotificationCard';
import { getNotificationStatusFromDates } from '../utils';
import { SlideOutContext } from '../../../common/slide-out/SlideOutWrapper';
import NotificationForm from '../notification-form/NotificationForm';

const ALL_NOTIFICATIONS_CONTENT = {
  CRITICAL: {
    heading: 'Critical & emergency notification',
    description: 'Publish, edit, and manage emergency notification.',
  },
  NON_CRITICAL: {
    heading: 'Non-critical notification',
    description: 'Publish, edit, and manage non-critical notifications.',
  },
};

const AllNotifications = ({ type }) => {
  const { getNotifications } = useContext(APIContext);
  const [notifications, setNotifications] = useState(null);
  const { setSlide, setOpen } = useContext(SlideOutContext);
  // tabs (filters)
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const tabs = [
    'All',
    ...NOTIFICATION_STATUS_IDS.map((status) => capitaliseFirstWord(status)),
  ];
  const selectedStatus = NOTIFICATION_STATUS_IDS[selectedTabIndex - 1];

  // filtered notification data
  const displayData =
    selectedTabIndex === 0
      ? notifications
      : notifications.filter(
          (notification) =>
            getNotificationStatusFromDates(
              notification.startDate,
              notification.endDate
            ) === selectedStatus
        );

  // sorted notification data (by start date)
  const displayDataSortedByStartDate = displayData?.sort(
    (a, b) => a.startDate - b.startDate
  );

  const openSlideForm = (data) => {
    setSlide({
      content: (
        <NotificationForm
          notification={data}
          setNotifications={setNotifications}
          type={type}
        />
      ),
    });
    setOpen(true);
  };

  // get notifications
  useEffect(() => {
    getNotifications(NOTIFICATION_TYPE_IDS[type])
      .then((r) => {
        setNotifications(r);
      })
      .catch((error) => {
        console.warn(
          '[getNotifications]: api call error, failed to get notifications.',
          error
        );
      });
  }, []);

  return (
    <div className={c.container}>
      <div className={c.allNotifications}>
        <div className={c.header}>
          <div className={c.headingGroup}>
            <h1>{ALL_NOTIFICATIONS_CONTENT[type].heading}</h1>
            <p>{ALL_NOTIFICATIONS_CONTENT[type].description}</p>
          </div>
          <Button
            icon={<Icon.Plus />}
            iconPosition="left"
            variant="primary"
            size="medium"
            onClick={() => openSlideForm(null)}
            aria-label="add new notification"
          >
            New
          </Button>
        </div>
        {displayDataSortedByStartDate ? (
          <>
            <div className={c.tabs}>
              <Tabs
                tabs={tabs}
                selected={selectedTabIndex}
                onChange={(i) => {
                  setSelectedTabIndex(i);
                }}
              />
            </div>
            <div className={c.notificationList}>
              {displayDataSortedByStartDate?.map((notification) => (
                <NotificationCard
                  notification={notification}
                  onClick={() => openSlideForm(notification)}
                  key={notification.id}
                />
              ))}
            </div>
          </>
        ) : (
          <LoadingIndicatorPlanet />
        )}
      </div>
    </div>
  );
};

export default AllNotifications;
