import React, { useState } from 'react';
import c from './notification-card.module.scss';
import { Collapsible, Icon, IconButton, SingleTag } from '@monash/portal-react';
import { format } from 'date-fns';
import { getNotificationStatusFromDates } from '../../utils';
import { capitaliseFirstWord } from '@monash/portal-frontend-common';

const NotificationCard = ({ notification, onClick }) => {
  const [expanded, setExpanded] = useState(false);
  const formatDate = (date) => format(date, 'd MMMM yyyy h:mmaaa');
  const status = getNotificationStatusFromDates(
    notification.startDate,
    notification.endDate
  );

  return (
    <button className={c.notificationCard} onClick={onClick}>
      <div className={c.header}>
        <div className={c.headingGroup}>
          <h3>{notification.title}</h3>
          <label>
            {`${formatDate(notification.startDate)} - ${formatDate(
              notification.endDate
            )}`}
          </label>
        </div>
        <div className={c.actions}>
          <SingleTag status={status} text={capitaliseFirstWord(status)} />
          <IconButton
            icon={expanded ? Icon.ChevronUp : Icon.ChevronDown}
            aria-label={expanded ? 'Collapse' : 'Expand'}
            onClick={(e) => {
              e.stopPropagation();
              setExpanded((f) => !f);
            }}
          />
        </div>
      </div>
      <Collapsible expanded={expanded}>
        <p className={c.description}>{notification.description}</p>
      </Collapsible>
    </button>
  );
};

export default NotificationCard;
