import './App.scss';
import React, { useContext } from 'react';
import {
  Route,
  Link,
  Topnav,
  FourOhFour,
  AppLayout,
} from '@monash/portal-react';
import {
  AppLauncherPad,
  AuthContext,
  MenuContext,
  PageNotFound,
  UserMenu,
} from '@monash/portal-frontend-common';
import ThemeBuilder from './components/admin/theme-builder/ThemeBuilder';
import Links from './components/links/Links';
import Applications from './components/admin/applications/Applications';
import Admin from './components/admin/Admin';
import ApiDocs from './components/admin/api-docs/ApiDocs';
import Impersonation from './components/admin/impersonation/Impersonation';
import PreviewPanel from './components/common/preview-panel/PreviewPanel';
import Notifications from './components/admin/notifications/Notifications';
import ACCESS_ROLES from './constants/user-roles';

const App = () => {
  const { handleMenu, getMenuRef, size } = useContext(MenuContext);
  const authCtx = useContext(AuthContext);
  const userRoles = authCtx?.user?.roles;

  return (
    <AppLayout
      topnav={
        <Topnav
          title="Admin"
          onMenu={handleMenu}
          size={size}
          getMenuRef={getMenuRef}
        >
          <div>
            <AppLauncherPad />
            <UserMenu />
          </div>
        </Topnav>
      }
      showSidebar={false}
      content={
        <>
          <Route to="/" exact>
            <Admin>
              <Applications />
            </Admin>
          </Route>
          {userRoles?.includes(ACCESS_ROLES.IMPERSONATION) && (
            <Route to="impersonation" exact>
              <Admin>
                <Impersonation />
              </Admin>
            </Route>
          )}
          {[
            ACCESS_ROLES.NOTIFICATIONS_CRITICAL,
            ACCESS_ROLES.NOTIFICATIONS_NON_CRITICAL,
          ].some((x) => userRoles?.includes(x)) && (
            <Notifications root="/notifications" />
          )}
          <Route to="theme-builder">
            <Admin>
              <ThemeBuilder />
            </Admin>
          </Route>
          <Route to="/preview">
            <Admin>
              <Applications />
            </Admin>
          </Route>
          {['dev', 'localhost'].some((x) =>
            window.location.host.includes(x)
          ) && (
            <Route to="api-docs" exact>
              <Admin>
                <ApiDocs />
              </Admin>
            </Route>
          )}
          <Links root="apps/links" />
          <PreviewPanel />
          <FourOhFour>
            <PageNotFound action={<Link to="/">Back to homepage</Link>} />
          </FourOhFour>
        </>
      }
    />
  );
};

export default App;
