import React, { useContext } from 'react';
import c from './theme-builder.module.scss';
import { ThemePreviewContext } from './ThemeBuilder';

const ThemePreview = ({ children }) => {
  const { theme } = useContext(ThemePreviewContext);

  return (
    <div className={c.ThemePreview} style={theme.variables}>
      {children}
    </div>
  );
};

export default ThemePreview;
