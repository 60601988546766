import React, { useState, useContext } from 'react';
import { SearchBox, Button, Icon } from '@monash/portal-react';
import c from './impersonation.module.scss';
import { APIContext } from '@monash/portal-frontend-common';
import UserCard from './UserCard';

const UserSearch = ({ onSelect, setRecentSearches, recentSearches }) => {
  const { checkStudentImpersonation } = useContext(APIContext);
  const [searchType, setSearchType] = useState(null); // null, real, test
  const [results, setResults] = useState(null);
  const [target, setTarget] = useState('');
  const [searched, setSearched] = useState('');
  const [loading, setLoading] = useState(false);

  const searchRealUsers = (x) => {
    setSearched(x);
    setLoading(true);
    checkStudentImpersonation(x)
      .then((response) => {
        setLoading(false);
        setSearchType('real');
        const { name, studentId } = response;

        setRecentSearches([
          ...recentSearches.filter((search) => search.studentId !== studentId),
          { name, studentId },
        ]);

        if (response) {
          setResults([response]);
        } else {
          setResults([]);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.error(err);
      });
  };

  const realUserSearchBar = (
    <div className={c.searchBar}>
      <SearchBox
        searchString={target}
        onInput={setTarget}
        onSearch={(x) => searchRealUsers(x)}
      />
      <Button onClick={() => searchRealUsers(target)} loading={loading}>
        Search
      </Button>
    </div>
  );

  return (
    <div className={c.searchContainer}>
      {!searchType ? (
        <div className={c.header}>
          <h1>Impersonate user</h1>
          <p>
            Impersonate a different user to deal with support problems or just
            to see how their UI looks like
          </p>
        </div>
      ) : (
        <Button
          icon={<Icon.ArrowLeft />}
          iconPosition="left"
          variant="text"
          size="small"
          onClick={() => {
            setResults(null);
            setSearchType(null);
          }}
        >
          Back
        </Button>
      )}
      {/* real users */}
      {(!searchType || searchType === 'real') && realUserSearchBar}

      {!!searchType && results && !loading && (
        <div>
          {results.length ? (
            results.map((user) => (
              <UserCard
                key={searched}
                data={{ ...user, studentId: searched }}
                onSelect={onSelect}
              />
            ))
          ) : (
            <span className={c.notFound}>"{searched}" doesn't exist</span>
          )}
        </div>
      )}
    </div>
  );
};

export default UserSearch;
