import {
  APIContext,
  getIcon,
  portalLink,
} from '@monash/portal-frontend-common';
import React, { useEffect, useState, useContext } from 'react';
import UserCard from './UserCard';
import UserSearch from './UserSearch';
import c from './impersonation.module.scss';
import RecentSearches from './RecentSearches';
import useLocalStorage from '../../../hooks/use-local-storage';
import { Button } from '@monash/portal-react';

const Impersonation = () => {
  const [recentSearches, setRecentSearches] = useLocalStorage(
    'recentSearches',
    []
  );

  const { isImpersonating } = useContext(APIContext);

  const [currentlyImpersonating, setCurrentlyImpersonating] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    isImpersonating().then((response) => {
      const { preferredName, lastName, studentId } = response;
      const details = {
        studentId,
        name: `${preferredName} ${lastName}`,
      };

      setCurrentlyImpersonating(
        response.currentlyImpersonating ? details : null
      );
      setLoading(false);
    });
  }, [isImpersonating]);

  const startImpersonation = (user) => {
    setCurrentlyImpersonating(user);
  };

  const AppLink = ({ icon, name, url }) => (
    <div className={c.link}>
      <div className={c.appName}>
        <span>{icon}</span>
        <span>{name}</span>
      </div>

      <a href={url} target="_blank" rel="noreferrer">
        <Button
          type="text"
          // onClick={() => window.open(url, '_blank')}
        >
          Visit
        </Button>
      </a>
    </div>
  );

  if (loading) {
    return (
      <div className={c.container}>
        <div>...</div>
      </div>
    );
  }

  return (
    <div className={c.container}>
      <div>
        {currentlyImpersonating && (
          <>
            <div className={c.searchContainer}>
              <h1>Currently Impersonating</h1>
              <UserCard
                data={currentlyImpersonating}
                onEnd={() => setCurrentlyImpersonating(false)}
                active
              />
            </div>

            <div className={c.applications}>
              <h4>Available applications</h4>
              <AppLink
                name="Portal Home"
                url={portalLink('home')}
                icon={getIcon()}
              />
              <AppLink
                name="Monash Links"
                url={portalLink('links')}
                icon={getIcon()}
              />
              <AppLink
                name="Profile"
                url={portalLink('profile')}
                icon={getIcon()}
              />
            </div>
          </>
        )}

        {!currentlyImpersonating && (
          <>
            <UserSearch
              onSelect={startImpersonation}
              recentSearches={recentSearches}
              setRecentSearches={setRecentSearches}
            />
            {Boolean(recentSearches.length) && (
              <RecentSearches
                recentSearches={recentSearches}
                onSelect={startImpersonation}
              />
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default Impersonation;
