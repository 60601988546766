import React from 'react';
import c from './tile-button.module.scss';

const TileButton = ({ icon, title, description, onClick }) => {
  return (
    <button className={c.tileButton} onClick={onClick}>
      <div className={c.heading}>
        {icon}
        <h3 className={c.title}>{title}</h3>
      </div>
      <p>{description}</p>
    </button>
  );
};

export default TileButton;
