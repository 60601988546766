import React, { useCallback, useEffect, useMemo, useState } from 'react';
import c from './slide-out.module.scss';

export const SlideOutContext = React.createContext();

const SlideOutWrapper = ({ children }) => {
  const [open, setOpen] = useState(false);
  const [slide, setSlide] = useState(null);
  const [halfWidth, setHalfWidth] = useState();

  const observer = useMemo(
    () =>
      new ResizeObserver((entries) =>
        setHalfWidth(entries[0].contentRect.width / 2)
      ),
    [open]
  );

  const wrapperWidth = useCallback(
    (node) => {
      if (node) observer.observe(node);
    },
    [observer]
  );

  useEffect(() => {
    return () => observer.disconnect();
  }, [observer]);

  return (
    <SlideOutContext.Provider
      value={{ halfWidth, setSlide, open, setOpen, slide }}
    >
      <div className={c.wrapper} ref={wrapperWidth}>
        <div style={{ width: open ? '50%' : '100%' }}>{children}</div>
        <div
          className={c.slideOutContainer}
          style={{ width: open ? '50%' : 0 }}
        >
          {slide && slide.content}
        </div>
      </div>
    </SlideOutContext.Provider>
  );
};

export default SlideOutWrapper;
