import * as Sentry from '@sentry/react';

const initMonitoring = (env) => {
  // SENTRY
  Sentry.init({
    dsn: 'https://3011c4820c4c8c4dee8afffd5eb361f4@o4507219149717504.ingest.us.sentry.io/4507338037395456',
    environment: env,
    release: process.env.REACT_APP_GIT_SHA,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    // Performance Monitoring
    tracesSampleRate: env === 'prod' ? 0.1 : 1.0, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
};

export default initMonitoring;
