// notification types
export const NOTIFICATION_TYPE_IDS = {
  CRITICAL: 'critical',
  NON_CRITICAL: 'non-critical',
};

// history event types
export const NOTIFICATION_HISTORY_EVENT_TYPE_IDS = [
  'created',
  'edited',
  'published',
  'updated',
  'expired',
];

// publish statuses
export const NOTIFICATION_STATUS_IDS = ['scheduled', 'active', 'expired'];

// form

// notification
export const NOTIFICATION_EMPTY = {
  // data fields
  apps: [],
  studentUserGroups: [],
  staffUserGroups: [],
  title: '',
  description: '',
  startDate: null,
  endDate: null,
  links: [],
  type: '',
  // form fields
  startTime: '',
  endTime: '',
};

export const NOTIFICATION_TYPE_EMPTY_FORMS = {
  CRITICAL: { ...NOTIFICATION_EMPTY, authorisedBy: '' },
  NON_CRITICAL: NOTIFICATION_EMPTY,
};

// apps for publish
export const NOTIFICATION_APPS = {
  STUDENT: {
    id: 'student',
    label: 'Student portal apps',
    userGroupsKey: 'studentUserGroups',
  },
  STAFF: {
    id: 'staff',
    label: 'Staff portal',
    userGroupsKey: 'staffUserGroups',
  },
};

// link
export const NOTIFICATION_EMPTY_LINK = {
  label: '',
  url: '',
};
