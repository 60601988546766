import { APIContext } from '@monash/portal-frontend-common';
import React, { useEffect, useState, useContext } from 'react';
import SwaggerUI from 'swagger-ui-react';
import 'swagger-ui-react/swagger-ui.css';

const ApiDocs = () => {
  const { getApiDocs } = useContext(APIContext);

  const [docs, setDocs] = useState(null);

  useEffect(() => {
    getApiDocs().then((r) => {
      setDocs(r);
    });
  }, [getApiDocs]);

  return (
    <div style={{ padding: '20px' }}>
      {/* {docs && <div dangerouslySetInnerHTML={{__html: docs}}/>} */}
      {/* {docs && <iframe title="docs" srcDoc={
        // docs?.slice(docs?.indexOf('<body>'), docs?.indexOf('</body>')+7)
        docs
      }/>} */}
      {docs && <SwaggerUI spec={docs} />}
    </div>
  );
};

export default ApiDocs;
