/* eslint-disable jsx-a11y/no-onchange */
/* eslint-disable jsx-a11y/control-has-associated-label */

import React, { useRef, useState } from 'react';
import c from './theme-builder.module.scss';
import ThemePreview from './ThemePreview';
import VariablesSetter from './VariablesSetter';
import {
  Card,
  Button,
  Dropdown,
  themes,
  combineThemeWithFallbacks,
  TextField,
} from '@monash/portal-react';

export const ThemePreviewContext = React.createContext();

const ThemeBuilder = () => {
  const [selectedBaseTheme, setSelectedBaseTheme] = useState(
    JSON.parse(localStorage.getItem('baseTheme')) || 'dark'
  );

  const [theme, setTheme] = useState(
    combineThemeWithFallbacks(themes[selectedBaseTheme])
  );
  const originalBaseThemes = useRef({
    dark: { ...combineThemeWithFallbacks(themes.dark).variables },
    light: { ...combineThemeWithFallbacks(themes.light).variables },
  });

  const changeBaseTheme = (value) => {
    localStorage.setItem('baseTheme', JSON.stringify(value));
    setSelectedBaseTheme(value);
    setTheme(combineThemeWithFallbacks(themes[value]));
  };

  // copy the theme to clipboard
  const copyToClipboard = () => {
    const themeCopy = { ...theme, baseTheme: selectedBaseTheme, variables: {} };

    // only add the variables that are changed from the base theme
    Object.keys(theme.variables).forEach((item, i) => {
      if (
        theme.variables[item] !==
        originalBaseThemes.current[selectedBaseTheme][item]
      )
        themeCopy.variables[item] = theme.variables[item];
    });

    navigator.clipboard.writeText(JSON.stringify(themeCopy, null, 2));
  };

  return (
    <ThemePreviewContext.Provider value={{ theme, setTheme }}>
      {/* import font */}
      <style>@import {`url('${theme.fontURL}')`}</style>

      <div className={c.meta}>
        {/* base theme selector */}
        <div className={c.baseTheme}>
          <div>Base theme: </div>
          <Dropdown
            selectedValue={selectedBaseTheme}
            onChange={(e) => changeBaseTheme(e)}
            data={['dark', 'light']}
          />
        </div>

        {/* copy changes to clipboard */}
        <Button onClick={copyToClipboard}>Copy to clipboard</Button>
      </div>

      {/* theme builder */}

      <div className={c.ThemeBuilder}>
        {/* global */}
        <div className={c.variables}>
          <h2>Global</h2>
          <VariablesSetter variable="Font Face import" fontFace />
          <VariablesSetter variable="--global-font-family" txt />
          <VariablesSetter variable="--global-font-size" txt />
          <VariablesSetter variable="--global-line-height" txt />
          <VariablesSetter variable="--global-text-color" color />
        </div>
        <ThemePreview>
          <Card>
            <div className={c.global}>
              <p>
                Do not go gentle into that good night,
                <br />
                Old age should burn and rave at close of day;
                <br />
                Rage, rage against the dying of the light.
              </p>
              <p>
                Though wise men at their end know dark is right,
                <br />
                Because their words had forked no lightning they
                <br />
                Do not go gentle into that good night.
              </p>
            </div>
          </Card>
        </ThemePreview>

        {/* intent */}
        <div className={c.variables}>
          <h2>Intent</h2>
          <VariablesSetter variable="--color-intent-success" color />
          <VariablesSetter variable="--color-intent-success-active" color />
          <VariablesSetter variable="--color-intent-warning" color />
          <VariablesSetter variable="--color-intent-warning-active" color />
          <VariablesSetter variable="--color-intent-attention" color />
          <VariablesSetter variable="--color-intent-attention-active" color />
          <VariablesSetter variable="--color-intent-disabled" color />
          <VariablesSetter variable="--color-intent-disabled-active" color />
        </div>
        <ThemePreview>
          <div
            className={c.intent}
            style={{
              borderColor: 'var(--color-intent-success)',
              backgroundColor: 'var(--color-intent-success)',
              color: 'var(--card-cta-text-color)',
            }}
          >
            Intent success
          </div>
          <div
            className={c.intent}
            style={{
              borderColor: 'var(--color-intent-success-active)',
              backgroundColor: 'var(--color-intent-success-active)',
              color: 'var(--card-cta-text-color)',
            }}
          >
            Intent success active
          </div>
          <div
            className={c.intent}
            style={{
              borderColor: 'var(--color-intent-warning)',
              backgroundColor: 'var(--color-intent-warning)',
              color: 'var(--card-cta-text-color)',
            }}
          >
            Intent warning
          </div>
          <div
            className={c.intent}
            style={{
              borderColor: 'var(--color-intent-warning-active)',
              backgroundColor: 'var(--color-intent-warning-active)',
              color: 'var(--card-cta-text-color)',
            }}
          >
            Intent warning active
          </div>
          <div
            className={c.intent}
            style={{
              borderColor: 'var(--color-intent-attention)',
              backgroundColor: 'var(--color-intent-attention)',
              color: 'var(--card-cta-text-color)',
            }}
          >
            Intent attention
          </div>
          <div
            className={c.intent}
            style={{
              borderColor: 'var(--color-intent-attention-active)',
              backgroundColor: 'var(--color-intent-attention-active)',
              color: 'var(--card-cta-text-color)',
            }}
          >
            Intent attention active
          </div>

          <div
            className={c.intent}
            style={{
              borderColor: 'var(--color-intent-disabled)',
              backgroundColor: 'var(--color-intent-disabled)',
              color: 'var(--card-cta-text-color)',
            }}
          >
            Intent disabled
          </div>
          <div
            className={c.intent}
            style={{
              borderColor: 'var(--color-intent-disabled-active)',
              backgroundColor: 'var(--color-intent-disabled-active)',
              color: 'var(--card-cta-text-color)',
            }}
          >
            Intent disabled active
          </div>
        </ThemePreview>

        {/* accent */}
        <div className={c.variables}>
          <h2>Accent</h2>
          <VariablesSetter variable="--color-accent-1" color />
          <VariablesSetter variable="--color-accent-2" color />
          <VariablesSetter variable="--color-accent-3" color />
          <VariablesSetter variable="--color-accent-4" color />
        </div>
        <ThemePreview>
          <div className={c.accent}>
            <div
              className={c.pill}
              style={{ backgroundColor: 'var(--color-accent-1)' }}
            />
            Accent 1
          </div>
          <div className={c.accent}>
            <div
              className={c.pill}
              style={{ backgroundColor: 'var(--color-accent-2)' }}
            />
            Accent 2
          </div>
          <div className={c.accent}>
            <div
              className={c.pill}
              style={{ backgroundColor: 'var(--color-accent-3)' }}
            />
            Accent 3
          </div>
          <div className={c.accent}>
            <div
              className={c.pill}
              style={{ backgroundColor: 'var(--color-accent-4)' }}
            />
            Accent 4
          </div>
        </ThemePreview>

        {/* card */}
        <div className={c.variables}>
          <h2>Card</h2>
          <VariablesSetter variable="--card-border-color" color />
          <VariablesSetter variable="--card-border-radius" txt />
          <VariablesSetter variable="--card-border-width" txt />
          <VariablesSetter variable="--card-border-style" txt />
          <VariablesSetter variable="--card-padding" txt />
          <VariablesSetter variable="--card-bg-color" color />
          {/* <VariablesSetter variable={'--card-bg-image'} /> */}
          <VariablesSetter variable="--card-bg-filter" txt />
          <VariablesSetter variable="--card-drop-shadow" txt />
          <VariablesSetter variable="--card-text-color" color />
        </div>
        <ThemePreview>
          <Card active>
            <p>
              Leverage agile frameworks to provide a robust synopsis for high
              level overviews. Iterative approaches to corporate strategy foster
              collaborative thinking to further the overall value proposition.
              Organically grow the holistic world view of disruptive innovation
              via workplace diversity and empowerment.
            </p>
          </Card>
        </ThemePreview>

        {/* card cta */}
        <div className={c.variables}>
          <h2>Card CTA</h2>
          {/* standard */}
          <h3>Standard</h3>
          <VariablesSetter variable="--card-cta-bg-color" color />
          <VariablesSetter variable="--card-cta-bg-color-alt" color />
          <VariablesSetter variable="--card-cta-border-color" color />
          <VariablesSetter variable="--card-cta-border-color-alt" color />
          <VariablesSetter variable="--card-cta-text-color" color />
          <VariablesSetter variable="--card-cta-text-color-alt" color />
          <VariablesSetter variable="--card-cta-border-width" txt />
          <VariablesSetter variable="--card-cta-border-radius" txt />
          <VariablesSetter variable="--card-cta-border-style" txt />
          <VariablesSetter variable="--card-cta-padding" txt />
          <VariablesSetter variable="--card-cta-text-size" txt />

          {/* active */}
          <h3>Active</h3>
          <VariablesSetter variable="--card-cta-active-bg-color" color />
          <VariablesSetter variable="--card-cta-active-bg-color-alt" color />
          <VariablesSetter variable="--card-cta-active-border-color" color />
          <VariablesSetter
            variable="--card-cta-active-border-color-alt"
            color
          />
          <VariablesSetter variable="--card-cta-active-text-color" color />
          <VariablesSetter variable="--card-cta-active-text-color-alt" color />

          {/* disabled */}
          <h3>Disabled</h3>
          <VariablesSetter variable="--card-cta-disabled-bg-color" color />
          <VariablesSetter variable="--card-cta-disabled-bg-color-alt" color />
          <VariablesSetter variable="--card-cta-disabled-border-color" color />
          <VariablesSetter
            variable="--card-cta-disabled-border-color-alt"
            color
          />
          <VariablesSetter variable="--card-cta-disabled-text-color" color />
          <VariablesSetter
            variable="--card-cta-disabled-text-color-alt"
            color
          />
        </div>
        <ThemePreview>
          <Card active>
            <div className={c.cardCtas}>
              <Button variant="primary">Primary</Button>
              <Button variant="secondary">Secondary</Button>
              <Button variant="text">Text</Button>
              <Button variant="delete">Delete</Button>
            </div>
          </Card>
        </ThemePreview>

        {/* card input */}
        <div className={c.variables}>
          <h2>Card input</h2>

          {/* standard */}
          <h3>Standard</h3>
          <VariablesSetter variable="--card-input-border-color" color />
          <VariablesSetter variable="--card-input-bg-color" color />
          <VariablesSetter variable="--card-input-text-color" color />
          <VariablesSetter variable="--card-input-placeholder-color" color />
          <VariablesSetter variable="--card-input-border-width" txt />
          <VariablesSetter variable="--card-input-border-radius" txt />
          <VariablesSetter variable="--card-input-border-style" txt />
          <VariablesSetter variable="--card-input-padding" txt />
          <VariablesSetter variable="--card-input-text-size" txt />

          {/* focused */}
          <h3>Focused</h3>
          <VariablesSetter variable="--card-input-focused-border-color" color />
          <VariablesSetter variable="--card-input-focused-bg-color" color />
          <VariablesSetter variable="--card-input-focused-text-color" color />

          {/* disabled */}
          <h3>Disabled</h3>
          <VariablesSetter
            variable="--card-input-disabled-border-color"
            color
          />
          <VariablesSetter variable="--card-input-disabled-bg-color" color />
          <VariablesSetter variable="--card-input-disabled-text-color" color />
        </div>
        <ThemePreview>
          <Card active>
            <div className={c.cardInputs}>
              <TextField placeholder="Text input" mode="light" />
              <TextField
                placeholder="Number input"
                type="number"
                mode="light"
              />
              <TextField placeholder="Text area" mode="light" multiline />

              <TextField placeholder="Text input" mode="light" disabled />
              <TextField
                placeholder="Number input"
                type="number"
                mode="light"
                disabled
              />
              <TextField
                placeholder="Text area"
                mode="light"
                multiline
                disabled
              />
            </div>
          </Card>
        </ThemePreview>

        {/* canvas */}
        <div className={c.variables}>
          <h2>Canvas</h2>
          <VariablesSetter variable="--canvas-bg-color" color />
          <VariablesSetter variable="--canvas-bg-image" txt="file" />
          <VariablesSetter variable="--canvas-text-color" color />
        </div>
        <ThemePreview>
          <div className={c.canvas}>
            <p>
              Bring to the table win-win survival strategies to ensure proactive
              domination. At the end of the day, going forward, a new normal
              that has evolved from generation X is on the runway heading
              towards a streamlined cloud solution. User generated content in
              real-time will have multiple touchpoints for offshoring.
            </p>
          </div>
        </ThemePreview>

        {/* canvas cta */}
        <div className={c.variables}>
          <h2>Canvas CTA</h2>
          {/* standard */}
          <h3>Standard</h3>
          <VariablesSetter variable="--canvas-cta-bg-color" color />
          <VariablesSetter variable="--canvas-cta-bg-color-alt" color />
          <VariablesSetter variable="--canvas-cta-border-color" color />
          <VariablesSetter variable="--canvas-cta-border-color-alt" color />
          <VariablesSetter variable="--canvas-cta-text-color" color />
          <VariablesSetter variable="--canvas-cta-text-color-alt" color />
          <VariablesSetter variable="--canvas-cta-border-width" txt />
          <VariablesSetter variable="--canvas-cta-border-radius" txt />
          <VariablesSetter variable="--canvas-cta-border-style" txt />
          <VariablesSetter variable="--canvas-cta-padding" txt />
          <VariablesSetter variable="--canvas-cta-text-size" txt />
          {/* active */}
          <h3>Active</h3>
          <VariablesSetter variable="--canvas-cta-active-bg-color" color />
          <VariablesSetter variable="--canvas-cta-active-bg-color-alt" color />
          <VariablesSetter variable="--canvas-cta-active-border-color" color />
          <VariablesSetter
            variable="--canvas-cta-active-border-color-alt"
            color
          />
          <VariablesSetter variable="--canvas-cta-active-text-color" color />
          <VariablesSetter
            variable="--canvas-cta-active-text-color-alt"
            color
          />
          {/* disabled */}
          <h3>Disabled</h3>
          <VariablesSetter variable="--canvas-cta-disabled-bg-color" color />
          <VariablesSetter
            variable="--canvas-cta-disabled-bg-color-alt"
            color
          />
          <VariablesSetter
            variable="--canvas-cta-disabled-border-color"
            color
          />
          <VariablesSetter variable="--canvas-cta-disabled-border-color-alt" />
          <VariablesSetter variable="--canvas-cta-disabled-text-color" color />
          <VariablesSetter
            variable="--canvas-cta-disabled-text-color-alt"
            color
          />
        </div>
        <ThemePreview>
          <div className={c.canvasCtas}>
            {/* standard */}
            <Button mode="canvas" variant="primary">
              Primary
            </Button>
            <Button mode="canvas" variant="secondary">
              Secondary
            </Button>
            <Button mode="canvas" variant="text">
              Text
            </Button>
            <Button mode="canvas" variant="delete">
              Delete
            </Button>
          </div>
        </ThemePreview>

        {/* canvas input */}
        <div className={c.variables}>
          <h2>Canvas Input</h2>

          {/* standard */}
          <h3>Standard</h3>
          <VariablesSetter variable="--canvas-input-border-color" color />
          <VariablesSetter variable="--canvas-input-bg-color" color />
          <VariablesSetter variable="--canvas-input-text-color" color />
          <VariablesSetter variable="--canvas-input-placeholder-color" color />
          <VariablesSetter variable="--canvas-input-border-width" txt />
          <VariablesSetter variable="--canvas-input-border-radius" txt />
          <VariablesSetter variable="--canvas-input-border-style" txt />
          <VariablesSetter variable="--canvas-input-padding" txt />
          <VariablesSetter variable="--canvas-input-text-size" txt />

          {/* focused */}
          <h3>Focused</h3>
          <VariablesSetter
            variable="--canvas-input-focused-border-color"
            color
          />
          <VariablesSetter variable="--canvas-input-focused-bg-color" txt />
          <VariablesSetter variable="--canvas-input-focused-text-color" color />

          {/* disabled */}
          <h3>Disabled</h3>
          <VariablesSetter
            variable="--canvas-input-disabled-border-color"
            color
          />
          <VariablesSetter variable="--canvas-input-disabled-bg-color" txt />
          <VariablesSetter
            variable="--canvas-input-disabled-text-color"
            color
          />
        </div>
        <ThemePreview>
          <div className={c.canvasInputs}>
            <TextField placeholder="Text input" mode="light" />
            <TextField placeholder="Number input" type="number" mode="light" />
            <TextField placeholder="Text area" mode="light" multiline />

            <TextField placeholder="Text input" mode="light" disabled />
            <TextField
              placeholder="Number input"
              type="number"
              mode="light"
              disabled
            />
            <TextField
              placeholder="Text area"
              mode="light"
              multiline
              disabled
            />
          </div>
        </ThemePreview>
      </div>
    </ThemePreviewContext.Provider>
  );
};

export default ThemeBuilder;
