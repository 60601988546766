import React from 'react';
import c from './inline-error-message.module.scss';
import { Icon } from '@monash/portal-react';

const InlineErrorMessage = ({ message }) => {
  return (
    <div className={c.inlineErrorMessage}>
      <Icon.Alert size={16} />
      <p>{message}</p>
    </div>
  );
};

export default InlineErrorMessage;
