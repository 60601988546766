import React, { useEffect } from 'react';
import c from './notification-app.module.scss';
import { useFormContext } from 'react-hook-form';
import { Button, Switch } from '@monash/portal-react';
import NotificationUserGroup from './notification-user-group/NotificationUserGroup';
import InlineErrorMessage from '../../../../../common/inline-error-message/InlineErrorMessage';
import availableORGroups from '../../../../../utilities/mock-data/available-OR-groups';

const NotificationApp = ({ app, apps }) => {
  const {
    setValue,
    watch,
    register,
    formState: { errors },
  } = useFormContext();
  const on = apps?.includes(app.id);
  const currentUserGroups = watch(app.userGroupsKey);
  const hasSelectableUserGroup = availableORGroups[app.id].some(
    (group) => !currentUserGroups?.includes(group)
  );

  register(app.userGroupsKey, {
    validate: (value) =>
      !on ||
      // filter empty selection
      (on && value.filter(Boolean).length !== 0) ||
      'Add at least one user group',
  });

  const switchOn = () =>
    setValue('apps', [...apps, app.id], { shouldValidate: true });

  const switchOff = () => {
    // remove app from apps field
    setValue(
      'apps',
      [...apps].filter((appId) => appId !== app.id),
      { shouldValidate: true }
    );
    // clear selected user groups of the app
    setValue(app.userGroupsKey, [], { shouldValidate: true });
  };

  // add empty string to render new dropdown without prefilled value
  const addUserGroup = () =>
    setValue(app.userGroupsKey, [...currentUserGroups, '']);

  // clean up empty strings (empty drop downs) if all user groups have already been selected
  useEffect(() => {
    if (!hasSelectableUserGroup) {
      setValue(app.userGroupsKey, [...currentUserGroups].filter(Boolean));
    }
  }, [hasSelectableUserGroup]);

  return (
    <div className={c.notificationApp}>
      <div className={c.appSwitch}>
        <Switch
          on={on}
          ariaLabel={app.label}
          onClick={on ? switchOff : switchOn}
        />
        <span>{app.label}</span>
      </div>
      {on && (
        <div className={c.userGroups}>
          <label>User groups</label>
          {currentUserGroups?.length !== 0 &&
            currentUserGroups.map((userGroup, i) => (
              <NotificationUserGroup
                key={`${i}-${userGroup}`}
                app={app}
                userGroups={currentUserGroups}
                userGroup={userGroup}
                index={i}
              />
            ))}
          {hasSelectableUserGroup && (
            <Button
              variant="text"
              mode="card"
              size="small"
              onClick={addUserGroup}
            >
              Add user group
            </Button>
          )}
          {errors[app.userGroupsKey] && (
            <InlineErrorMessage message={errors[app.userGroupsKey]?.message} />
          )}
        </div>
      )}
    </div>
  );
};

export default NotificationApp;
