import {
  DatePicker,
  RadioButton,
  RadioGroup,
  TimePicker,
} from '@monash/portal-react';
import React, { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import c from '../notification-form.module.scss';
import InlineErrorMessage from '../../../../common/inline-error-message/InlineErrorMessage';
import { getTimestampFromDateAndTimeInputs } from '../utils';

const NotificationStartDateAndTime = ({ startNow, setStartNow }) => {
  const {
    watch,
    clearErrors,
    setValue,
    trigger,
    control,
    formState: { errors, touchedFields },
  } = useFormContext();
  const currentStartDate = watch('startDate');
  const currentStartTime = watch('startTime');

  const onClickStartNow = () => {
    setStartNow(true);
    setValue('startDate', null);
    setValue('startTime', '');
    clearErrors(['startDate', 'startTime']);
  };

  const validateStartDate = (value) => {
    if (!value) return 'A date must be selected';
    const currentTimeStamp = getTimestampFromDateAndTimeInputs(
      value,
      currentStartTime
    );
    if (currentStartTime && currentTimeStamp < Date.now())
      return 'Date is in the past';
  };

  const getErrorMessage = () => {
    if (!currentStartDate && !currentStartTime) {
      return 'A day and a time must be selected';
    } else if (errors.startDate) {
      return errors.startDate?.message;
    } else if (errors.startTime) {
      return 'A time must be selected';
    }
  };

  useEffect(() => {
    // select day is set when textField onBlur fires hence validation must be triggered as an effect
    if (touchedFields.startDate) trigger('startDate');
    // end date validation contains logic that could be affected by changing start date and time
    if (touchedFields.endDate) trigger('endDate');
  }, [currentStartDate, currentStartTime]);

  return (
    <div className={c.inputGroup}>
      <RadioGroup heading="Start date and time" headingLevel="label">
        <RadioButton
          name="Start now"
          onClick={onClickStartNow}
          on={startNow}
          label="Start now"
        />
        <RadioButton
          name="Schedule start"
          onClick={() => setStartNow(false)}
          on={!startNow}
          label="Schedule start"
        />
      </RadioGroup>
      {!startNow && (
        <>
          <div className={c.dateAndTime}>
            <Controller
              control={control}
              name="startDate"
              rules={{
                validate: validateStartDate,
              }}
              render={({ field: { onBlur, onChange, value } }) => (
                <DatePicker
                  dateFormat="dd/MM/yyyy"
                  disablePast={true}
                  selectedDay={value}
                  setSelectedDay={onChange}
                  onBlur={onBlur}
                />
              )}
            />
            <Controller
              control={control}
              name="startTime"
              rules={{
                required: true,
              }}
              render={({ field: { onBlur, onChange, value } }) => (
                <TimePicker time={value} onChange={onChange} onBlur={onBlur} />
              )}
            />
          </div>
          {(errors.startDate || errors.startTime) && (
            <InlineErrorMessage message={getErrorMessage()} />
          )}
        </>
      )}
    </div>
  );
};

export default NotificationStartDateAndTime;
