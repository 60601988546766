export const getNotificationStatusFromDates = (startDate, endDate) => {
  const currentDate = Date.now();

  if (currentDate < startDate) {
    return 'scheduled';
  }

  if (currentDate >= startDate && currentDate < endDate) {
    return 'active';
  }

  if (currentDate >= endDate) {
    return 'expired';
  }
};
