import {
  Icon,
  LoadingIndicator,
  SearchBox,
  Button,
} from '@monash/portal-react';
import React, { useContext, useEffect, useState, useCallback } from 'react';
import { SlideOutContext } from '../../common/slide-out/SlideOutWrapper';
import c from './all-links-admin.module.scss';
import LinkForm from '../link-form/LinkForm';
import { APIContext } from '@monash/portal-frontend-common';

const AllLinks = () => {
  const { setSlide, setOpen, slide } = useContext(SlideOutContext);
  const { search, updateLink, createLink, disableLink } =
    useContext(APIContext);

  const [searchData, setSearchData] = useState([]);
  const [searchTarget, setSearchTarget] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(false);

  const triggerSearch = useCallback(() => {
    search('', [], [], 'link').then((r) => {
      setSearchData(r.results || []);
      setLoading(false);
    });
    setLoading(true);
  }, [search]);

  useEffect(() => {
    triggerSearch();
  }, [triggerSearch]);

  // Filter search data
  useEffect(() => {
    if (searchTarget) {
      const searchTerms = searchTarget.replace(/[^a-zA-Z ]/g, ' ').split(' ');
      console.log(searchTerms);
      const filtered = searchData.filter((data) =>
        Object.keys(data.keywords).some((keyword) =>
          searchTerms.some((term) => keyword.includes(term))
        )
      );
      setFilteredData(filtered);
    } else {
      setFilteredData(searchData);
    }
  }, [searchData, searchTarget]);

  const submitLink = (link) => {
    const linkPromise = link.id ? updateLink(link) : createLink(link);

    //  do whatever you need to do here
    linkPromise.then((r) => {
      openSlideForm(r);
      triggerSearch();
    });

    //  then let the children do something if needed
    return linkPromise;
  };

  const deleteLink = (link) => {
    disableLink(link.id, true).then((r) => {
      console.log(link.id, 'deleted');
      // search() //maybe dont need this if delete locally
    });
    if (slide?.data?.id === link.id) {
      // is open in slideOut
      setOpen(false);
    }
    // remove from local list
    const i = searchData.findIndex((data) => data.entityId === link.id);
    if (i >= 0) {
      const deleting = [...searchData];
      deleting.splice(i, 1);
      setSearchData(deleting);
    }
  };

  const openSlideForm = (link) => {
    setSlide({
      content: (
        <LinkForm
          existing={link}
          deleteLink={() => deleteLink(link)}
          submit={submitLink}
          cancel={() => setOpen(false)}
          getKeywords={() => getKeywords(link?.id)}
        />
      ),
      data: link,
    });
    setOpen(true);
  };

  const getKeywords = (id) => {
    if (!id) return null;
    const keywords =
      searchData.filter((i) => i.entityId === id)[0]?.keywords || {};
    return { description: keywords };
  };

  return (
    <div className={c.container}>
      <div className={c.allLinks}>
        <div className={c.header}>
          <h1>Links</h1>
          <Button
            icon={<Icon.Plus />}
            iconPosition="left"
            variant="primary"
            size="medium"
            onClick={() => {
              openSlideForm(null);
            }}
            aria-label="add link"
          >
            Add link
          </Button>
        </div>
        <div>
          <SearchBox searchString={searchTarget} onInput={setSearchTarget} />
        </div>
        <p>{searchData.length} links</p>
        {loading ? (
          <div className={c.loading}>
            <LoadingIndicator />
          </div>
        ) : (
          <div className={c.linksList}>
            {filteredData.map((indexed, i) => {
              const link = indexed.entityContent;
              return (
                <button
                  className={c.link}
                  onClick={() => openSlideForm(link)}
                  key={i}
                >
                  <div className={c.linkDetails}>
                    <p>{link.name}</p>
                    <label>{link.url}</label>
                  </div>
                </button>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default AllLinks;
