import React, { useEffect, useState } from 'react';
import { LoadingIndicator } from '@monash/portal-react';
import c from './loadingScreen.module.scss';

const LoadingScreen = ({ children, message, loaded, style }) => {
  const [mount, setMount] = useState(false);

  useEffect(() => {
    if (loaded) {
      setTimeout(() => setMount(true), 10);
    } else {
      setMount(false);
    }
    // setMount(false)
  }, [loaded]);

  // render

  return (
    <>
      {mount ? (
        children
      ) : (
        <div
          className={c.loadingScreen}
          style={{ ...style, opacity: loaded ? 0 : 1 }}
        >
          <div>
            <LoadingIndicator />
          </div>
          <div className={c.message}>{message}</div>
        </div>
      )}
    </>
  );
};

export default LoadingScreen;
