import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {
  MegaWrapper,
  AuthProvider,
  APIProvider,
  MenuProvider,
  accessControl,
} from '@monash/portal-frontend-common';
import LoadingScreen from './components/utilities/loading/LoadingScreen';
import { ContextMenuWrapper, Router } from '@monash/portal-react';
import ThemeProvider from './components/providers/ThemeProvider';
import DataProvider from './components/providers/DataProvider';
import initMonitoring from './initMonitoring';

const env = process.env.REACT_APP_ENV;
if (env !== 'local') {
  initMonitoring(env);
}

const container = document.getElementById('root');
const root = createRoot(container);

const loadingScreen = (
  <LoadingScreen
    loaded={false}
    style={{ height: '100vh' }}
    message="Logging in"
  />
);

const wrappers = [
  {
    comp: AuthProvider,
    props: {
      nolanding: true,
      loadingPage: loadingScreen,
      accessControl: accessControl.isAdminUser,
    },
  },
  React.StrictMode,
  APIProvider,
  MenuProvider,
  DataProvider,
  ThemeProvider,
  ContextMenuWrapper,
  Router,
];

root.render(
  <MegaWrapper providers={wrappers}>
    <App />
  </MegaWrapper>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
