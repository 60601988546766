import React, { useContext, useEffect, useState } from 'react';
import {
  useFieldArray,
  Controller,
  useForm,
  FormProvider,
} from 'react-hook-form';
import { Button, Icon, TextField } from '@monash/portal-react';
import c from './notification-form.module.scss';
import { SlideOutContext } from '../../../common/slide-out/SlideOutWrapper';
import NotificationLink from './notification-link/NotificationLink';
import NotificationApps from './notification-apps/NotificationApps';
import {
  NOTIFICATION_EMPTY_LINK,
  NOTIFICATION_TYPE_EMPTY_FORMS,
  NOTIFICATION_TYPE_IDS,
} from '../../../../constants/notifications-props';
import SlideOutForm from '../../../common/slide-out/SlideOutForm';
import NotificationEndDateAndTime from './notification-date-and-time/NotificationEndDateAndTime';
import NotificationStartDateAndTime from './notification-date-and-time/NotificationStartDateAndTime';
import {
  transformFormDataForSubmission,
  transformNotificationDataForForm,
} from './utils';
import { APIContext } from '@monash/portal-frontend-common';
import isEqual from 'lodash.isequal';

const NotificationForm = ({ notification, type, setNotifications }) => {
  const { halfWidth, open, setOpen } = useContext(SlideOutContext);
  const { createNotification, deleteNotification, updateNotification } =
    useContext(APIContext);
  const [startNow, setStartNow] = useState(!notification?.startDate);
  const isExpired = notification?.endDate < Date.now();
  const isScheduled = notification?.startDate > Date.now();

  const initialValues =
    transformNotificationDataForForm(notification) ||
    NOTIFICATION_TYPE_EMPTY_FORMS[type];

  const methods = useForm({
    mode: 'onBlur',
    defaultValues: initialValues,
  });

  const {
    reset,
    control,
    watch,
    trigger,
    getValues,
    formState: { errors, isValid },
  } = methods;

  // Current form values
  const currentTitle = watch('title');
  const currentValues = getValues();

  // form states
  const isDirty = !isEqual(currentValues, initialValues);

  // links
  const {
    fields: links,
    append: appendLink,
    remove: removeLink,
  } = useFieldArray({
    name: 'links',
    control,
  });

  const submitNotification = (e) => {
    e.preventDefault();
    if (isValid) {
      const transformedDataForSubmission = transformFormDataForSubmission(
        currentValues,
        startNow,
        NOTIFICATION_TYPE_IDS[type]
      );
      createNotification(transformedDataForSubmission)
        .then((r) => {
          setNotifications((f) => [...f, r]);
          setOpen(false);
        })
        .catch((error) => {
          console.warn(
            '[createNotification]: api call error, failed to create notification.',
            error
          );
        });
    } else {
      trigger();
    }
  };

  const saveNotification = (e) => {
    e.preventDefault();
    // if form has not been changed, close panel
    if (!isDirty) {
      setOpen(false);
      // if form has been changed, update notification
    } else if (isValid) {
      const transformedDataForSubmission = transformFormDataForSubmission(
        currentValues,
        startNow,
        NOTIFICATION_TYPE_IDS[type]
      );
      updateNotification(transformedDataForSubmission)
        .then((r) => {
          setNotifications((f) => {
            const notificationIndex = f?.findIndex((item) => item.id === r.id);
            const newNotifications = [...f];
            newNotifications[notificationIndex] = r;
            return newNotifications;
          });
          setOpen(false);
        })
        .catch((error) => {
          console.warn(
            '[updateNotification]: api call error, failed to update notification.',
            error
          );
        });
    } else {
      trigger();
    }
  };

  const onDeleteNotification = async () => {
    try {
      const response = await deleteNotification(notification?.id);
      if (response) {
        setOpen(false);
        setNotifications((f) => {
          const notificationIndex = f?.findIndex(
            (item) => item.id === response.deleted
          );
          const newNotifications = [...f];
          newNotifications.splice(notificationIndex, 1);
          return newNotifications;
        });
        return response;
      }
    } catch (error) {
      console.warn(
        '[deleteNotification]: api call error, failed to delete notification.',
        error
      );
      throw error;
    }
  };

  // slide form props
  const formHeaderActions = [
    {
      icon: Icon.ClockReverse,
      onClick: () => {},
      label: 'View history',
      disabled: !notification,
    },
    {
      icon: Icon.Trash,
      label: 'Delete notification',
      disabled: !(notification && isScheduled),
      modalProps: {
        title: `Delete notification`,
        content: 'Are you sure you want to delete this notification?',
        cta: {
          async: true,
          onClick: onDeleteNotification,
          variant: 'delete',
          label: 'Delete',
        },
      },
    },
  ];

  const formSubmitActions = [
    {
      onClick: notification ? saveNotification : submitNotification,
      label: startNow ? 'Publish' : 'Save',
      disabled: isExpired,
    },
  ];

  // reset form values and local state
  useEffect(() => {
    reset(initialValues);
    setStartNow(!notification?.startDate);
  }, [open, notification]);

  return (
    <FormProvider {...methods}>
      <SlideOutForm
        name="notification"
        width={halfWidth}
        heading={currentTitle}
        headerActions={formHeaderActions}
        submitActions={formSubmitActions}
        isDirty={isDirty}
      >
        <div className={c.notificationForm}>
          <div className={c.inputGroup}>
            <label>Title</label>
            <Controller
              control={control}
              name="title"
              rules={{
                required: 'Title cannot be empty',
              }}
              render={({ field: { onBlur, onChange, value } }) => (
                <TextField
                  onBlur={onBlur}
                  onChange={onChange}
                  value={value}
                  placeholder="Untitled"
                  error={() => errors.title}
                  errorMsg={errors.title?.message}
                />
              )}
            />
          </div>
          <NotificationStartDateAndTime
            startNow={startNow}
            setStartNow={setStartNow}
            notification={notification}
            open={open}
          />
          <NotificationEndDateAndTime />
          <div className={c.inputGroup}>
            <label>Apps to publish</label>
            <NotificationApps />
          </div>
          <div className={c.inputGroup}>
            <label>Description</label>
            <Controller
              control={control}
              name="description"
              rules={{
                required: 'Description must not be empty',
              }}
              render={({ field: { onBlur, onChange, value } }) => (
                <TextField
                  onBlur={onBlur}
                  onChange={onChange}
                  value={value}
                  placeholder="Description"
                  multiline
                  height="12rem"
                  error={() => errors.description}
                  errorMsg={errors.description?.message}
                />
              )}
            />
          </div>
          <div className={c.inputGroup}>
            <label>Link (Optional)</label>
            <div className={c.links}>
              {links.length !== 0 &&
                links.map((link, i) => (
                  <NotificationLink
                    key={link.id}
                    index={i}
                    removeLink={removeLink}
                  />
                ))}
            </div>
            <Button
              variant="text"
              mode="card"
              size="small"
              onClick={() => appendLink(NOTIFICATION_EMPTY_LINK)}
            >
              Add link
            </Button>
          </div>
          {NOTIFICATION_TYPE_IDS[type] === 'critical' && (
            <div className={c.inputGroup}>
              <label>Authorised by</label>
              <Controller
                control={control}
                name="authorisedBy"
                rules={{
                  required: 'Authorised by must not be empty',
                }}
                render={({ field: { onBlur, onChange, value } }) => (
                  <TextField
                    onBlur={onBlur}
                    onChange={onChange}
                    value={value}
                    placeholder="Name"
                    error={() => errors.authorisedBy}
                    errorMsg={errors.authorisedBy?.message}
                  />
                )}
              />
            </div>
          )}
        </div>
      </SlideOutForm>
    </FormProvider>
  );
};

export default NotificationForm;
