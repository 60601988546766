import React, { useState } from 'react';
import { themes, combineThemeWithFallbacks } from '@monash/portal-react';

export const Theme = React.createContext();

const ThemeProvider = ({ children }) => {
  const [selectedTheme, setSelectedTheme] = useState('light');

  const selectTheme = (themeName) => {
    setSelectedTheme(themeName);
  };

  const currentTheme = combineThemeWithFallbacks(themes[selectedTheme]);

  const style = (
    <style>
      {`
        @import url("${currentTheme.fontURL}");

        :root {${Object.keys(currentTheme.variables)
          .map((key) => {
            return `${key}:${currentTheme.variables[key]}`;
          })
          .join(';')};
      `}
    </style>
  );

  // render

  return (
    <Theme.Provider
      value={{
        selectTheme,
        selectedTheme,
      }}
    >
      {/* style */}
      {style}

      {/* children */}
      {children}
    </Theme.Provider>
  );
};

export default ThemeProvider;
