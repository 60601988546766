export const OR_GROUPS = {
  OR0018: 'Australia students',
  OR0022: 'Malaysia students',
  OR0011: 'Monash College Students',
  OR0017: 'Australia staff',
  OR0051: 'Mannix College staff',
  OR0052: 'Hudson staff',
  OR0057: 'MSA Affiliate staff',
  OR0008: 'JMSS staff',
  OR0010: 'Monash College staff',
  OR0021: 'Malaysia staff',
};
